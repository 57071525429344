import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './Header';
import SidebarComponent from './Sidebar';
import Costs from '../pages/Costs';
import Sales from '../pages/Sales';
import ExpenseReports from '../pages/ExpenseReports';
import Suppliers from '../listComponents/Suppliers';
import Customers from '../listComponents/Customers';
import Categories from '../listComponents/Categories';
import TaxRates from '../listComponents/TaxRates';
import Projects from '../listComponents/Projects';
import BankAccounts from '../listComponents/BankAccounts';
import PaymentMethods from '../listComponents/PaymentMethods';
import SystemSetting from '../manageComponent/SystemSetting';
import Mydetails from '../manageComponent/Mydetails';
import AccountDetails from '../manageComponent/AccountDetails';
import Subscription from '../manageComponent/Subscription';
import SubmissionHistory from '../manageComponent/SubmissionHistory';
import MyTeam from '../manageComponent/MyTeam';
import AllSupplier from '../listComponents/suppliers/Allsuppliers';
import MyClients from '../manageComponent/MyClients';
import { TooltipProvider } from '../contexts/TableTooltip';
import SuppplierPage from '../pages/SuppplierPage';
import CustomerPage from '../pages/CustomerPage';
const DashboardPanel = () => {
  return (
    <div className='h-screen w-[100vw]'>
      <div className='h-[10vh]'>
        <Header />
      </div>
      <div className="flex h-[90vh]">
        <SidebarComponent />
        <div className="flex-1 flex flex-col bg-[#ebf0ec] overflow-y-hidden  ">
          <main className="flex-1 overflow-y-auto  p-1 bg-white rounded-md mx-3 ">
            <Routes>
              <Route path="costs/*" element={
                <TooltipProvider>
                  <Costs />
                </TooltipProvider>} />
              <Route path="sales/*" element={
                <TooltipProvider>
                  <Sales />
                </TooltipProvider>} />
              <Route path='expensereports/*' element={<ExpenseReports />} />
              {/* <Route path='suppliers/*' element={<Suppliers />} /> */}
              {/* <Route path='suppliers/*' element={<AllSupplier />} /> */}
              {/* <Route path="suppliers/*" element={<Suppliers />}>
                <Route path=":id" element={<AllSupplier />} />
              </Route> */}
              <Route path="suppliers/*" element={<SuppplierPage />} />

              <Route path='customers/*' element={<CustomerPage />} />
              <Route path='categories' element={<Categories />} />
              <Route path='taxrates' element={<TaxRates />} />
              <Route path='projects' element={<Projects />} />
              <Route path='bankaccounts' element={< BankAccounts />} />
              <Route path='paymentmethods' element={<PaymentMethods />} />
              <Route path='systemsetting' element={<SystemSetting />} />
              <Route path='mydetails' element={<Mydetails />} />
              <Route path='accountdetails' element={<AccountDetails />} />
              <Route path='subscription' element={<Subscription />} />
              <Route path='submissionhistory' element={<SubmissionHistory />} />
              <Route path='myteam' element={<MyTeam />} />
              <Route path='myclients' element={<MyClients />} />

            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
};

export default DashboardPanel;
