import React, { useState, useContext, useEffect } from 'react'
import { Box, Button, IconButton, Popover } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { filterFields } from './InboxTableFilterFields'
import InboxTableAdvance from "./InboxTableAdvance";
import { TooltipContext } from '../../contexts/TableTooltip';

const initialFormData = {
    searchTerm: '',
    supplier: null,
    amountFrom: '',
    amountTo: '',
    dateFrom: null,
    dateTo: null,
    dueDateFrom: null,
    dueDateTo: null,
    uploadDateFrom: null,
    uploadDateTo: null,
    documentReference: '',
    itemId: '',
    type: null,
    currencyCode: null,
    submittedBy: null,
    submissionMethod: null,
    customer: null,
    category: null,
    expenseClaim: '',
    addedToExpenseClaim: false,
}

const TableExtendedToolTip = ({ rowsData }) => {


    const filterTabs = {
        // 'Ready': null,
        'Tax': null,
        // 'Tax Rate': null,
        'Category': null,
        'Currency': null,
        'Read': null,
        // 'Publishing': null,
        'Flag': null,
        // 'Merged': null,
        // 'Duplicates': null,
        'Paid': null,
        // 'Has Match': null,
        // 'Auto-categorised': null
    }
    const primaryColumns = [
        { id: 'status', numeric: false, disablePadding: false, label: ' Status' },
        { id: 'date', numeric: true, disablePadding: false, label: 'Date' },
        { id: 'supplier', numeric: true, disablePadding: false, label: 'Supplier' },
        { id: 'category', numeric: true, disablePadding: false, label: 'Category' },
        { id: 'total', numeric: true, disablePadding: false, label: 'Total' },
        { id: 'tax', numeric: true, disablePadding: false, label: 'Tax' },
        // { id: 'taxRate', numeric: true, disablePadding: false, label: 'Tax Rate' },
        // { id: 'match', numeric: false, disablePadding: false, label: 'Match' },
    ]

    const additionalColumns = [{ id: 'submittedBy', numeric: true, disablePadding: false, label: 'Submitted By' },
    { id: 'dueDate', numeric: true, disablePadding: false, label: 'Due Date' },
    { id: 'documentReference', numeric: true, disablePadding: false, label: 'Document Reference ' },
    { id: 'baseTotal', numeric: true, disablePadding: false, label: 'Base Total' },
    // { id: 'description', numeric: true, disablePadding: false, label: 'Description' },
    { id: 'paid', numeric: true, disablePadding: false, label: 'Paid' },
    // { id: 'payment', numeric: true, disablePadding: false, label: 'Payment' },
    { id: 'type', numeric: true, disablePadding: false, label: 'Type' },
    { id: 'itemId', numeric: true, disablePadding: false, label: 'Item Id' },
    { id: 'submissionMethod', numeric: true, disablePadding: false, label: 'Submission Method' },
    // { id: 'note', numeric: true, disablePadding: false, label: 'Note' },
    { id: 'uploadDate', numeric: true, disablePadding: false, label: 'Upload Date' },

    ];

    const settingReset = {
        'status': true,
        'date': true,
        'supplier': true,
        'category': true,
        'total': true,
        'tax': true,
        'taxRate': true,
        'match': null,
        'submittedBy': null,
        'dueDate': null,
        'documentReference': null,
        'baseTotal': null,
        'description': null,
        'paid': null,
        'payment': null,
        'type': null,
        'itemId': null,
        'submissionMethod': null,
        'note': null,
        'uploadDate': null,

    }
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedTabFilter, setSelectedTabFilter] = useState(filterTabs);
    const { tableColumns, setTableColumns, filters, setFilters, formData, setFormData } = useContext(TooltipContext)
    const [tempCheckedColumns, setTempCheckedColumns] = useState({});
    const [tempfilters, setTempFilters] = useState({});


    const [anchorEl1, setAnchorEl1] = useState(null);
    const open1 = Boolean(anchorEl1);
    const id1 = open1 ? 'advance-popover' : undefined;
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const id2 = open2 ? 'filter-popover' : undefined;
    const [anchorEl3, setAnchorEl3] = useState(null);
    const open3 = Boolean(anchorEl3);
    const id3 = open3 ? 'setting-popover' : undefined;

    const handleCheckBox = (e) => {
        const { id } = e.target;
        setTempCheckedColumns((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    }

    useEffect(() => {
        setTempCheckedColumns(tableColumns);
    }, [tableColumns]);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const handleClose3 = () => {
        setAnchorEl3(null);

    };
    useEffect(() => {
        setFormData(initialFormData)
        return () => {
            setTableColumns(settingReset)
            setFilters(filterTabs)
            setFormData(initialFormData)
        }
    }, [])
    return (
        <div className="flex gap-1 items-center">
            <Box className="relative"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '270px',
                    // boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                    border: '2px solid #dbdbd9 ',
                    borderRadius: '4px',
                    padding: '0.2rem 0.4rem'
                }}
            >
                <SearchIcon sx={{ color: '#dbdbd9' }} />
                <input className="focus:outline-none w-[50%]" />
                <Button
                    variant="text"
                    endIcon={open1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    sx={{
                        color: '#647890',
                        borderRadius: '0 4px 4px 0',
                        textTransform: 'none',
                        padding: '0 12px',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }}
                    onClick={handleClick1}
                >
                    Advanced
                </Button>
                <Popover
                    id={id1}
                    open={open1}
                    anchorEl={anchorEl1}
                    onClose={handleClose1}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className='p-2'>
                        <div className=" max-h-[500px] overflow-y-auto text-[#647890] text-sm" >
                            <InboxTableAdvance rowsData={rowsData} formData={formData} setFormData={setFormData} />



                        </div>
                        <div className="mt-2 flex p-1 justify-end items-center gap-6" >

                            <button className="font-semibold text-sm text-[#647890] active:translate-y-1 " onClick={() => { setFormData(initialFormData) }} >Reset</button>
                            <button className="p-2 font-semibold text-sm bg-[#1976D2] text-white  shadow-[60px_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-sm active:translate-y-1 " onClick={() => { setAnchorEl1(null); console.log(formData) }} >APPLY</button>

                        </div>
                    </div>


                </Popover>

            </Box>


            {/*//////////////////// Filter List /////////////////////////////////////////////*/}
            <IconButton onClick={handleClick2}>
                <FilterAltOutlinedIcon className="relative active:translate-y-1" sx={{ color: '#647890' }} />
            </IconButton>
            <Popover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className=" p-4 " >
                    <div className="  overflow-y-auto" >
                        <div className="font-bold text-sm text-[#647890] mb-3">FILTER</div>
                        <div className="flex flex-col gap-4 mb-1">
                            {filterFields.map((item, index) => {
                                let field = Object.keys(item)
                                field = field[0]
                                return (<div className="flex  justify-between gap-8">
                                    <span>{field}</span>
                                    <div className="flex w-[70%] justify-start gap-3 shadow-[60px_35px_60px_-15px_rgba(0,0,0,0.3)]">
                                        <button onClick={() => { setSelectedTabFilter((prev) => ({ ...prev, [field]: 1 })); setTempFilters((prev) => ({ ...prev, [field]: 'true' })) }} className={`border-2 w-fit text-[#647890] text-xs rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${selectedTabFilter[field] === 1 ? 'bg-[#fae396] border-[#ffe11f] text-black ' : 'border-[#647890]'}`}>{item[field][0]}</button>
                                        <button onClick={() => { setSelectedTabFilter((prev) => ({ ...prev, [field]: 2 })); setTempFilters((prev) => ({ ...prev, [field]: 'false' })) }} className={`border-2  w-fit  text-[#647890] text-xs rounded-md cursor-pointer hover:bg-[#fae396]  hover:border-[#ffe11f] flex justify-center items-center  p-1 ${selectedTabFilter[field] === 2 ? 'bg-[#fae396] border-[#ffe11f] text-black ' : 'border-[#647890]'}`}>{item[field][1]}</button>
                                    </div>
                                </div>)
                            })}
                        </div>


                    </div>
                    <div className="mt-2 flex p-1 justify-end items-center gap-6" >

                        <button className="font-semibold text-sm text-[#647890] active:translate-y-1 " onClick={() => { setSelectedTabFilter(filterTabs); setTempFilters({}) }}>Reset</button>
                        <button className="p-2 font-semibold text-sm bg-[#2196f3] text-white shadow-[60px_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-sm active:translate-y-1 " onClick={() => { setAnchorEl2(null); setFilters(tempfilters); console.log(filters) }} >Apply</button>

                    </div>

                </div>

            </Popover >


            {/*//////////////////// Setting List /////////////////////////////////////////////*/}
            <IconButton IconButton onClick={handleClick3} >
                <SettingsOutlinedIcon className="relative active:translate-y-1" sx={{ color: '#647890' }} />
            </IconButton >
            <Popover
                id={id3}
                open={open3}
                anchorEl={anchorEl3}
                onClose={handleClose3}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="p-4"  >
                    <div className="overflow-y-auto" >
                        <div className="font-bold text-xs text-[#647890] mb-3">PRIMARY COLUMNS</div>
                        <div className="grid grid-cols-2 gap-4 mb-4 text-xs">
                            {primaryColumns.map((item, index) => (
                                <div key={index} className="flex items-center">
                                    <input type="checkbox" id={item.id} checked={Boolean(tempCheckedColumns[item.id])} onChange={handleCheckBox} className="mr-2 " />
                                    <label htmlFor={item.id}>{item.label}</label>
                                </div>
                            ))}
                        </div>

                        <div className="font-bold text-xs text-[#647890] mb-3">ADDITIONAL COLUMNS</div>
                        <div className="grid grid-cols-2 gap-4 text-xs">
                            {additionalColumns.map((item, index) => (
                                <div key={index} className="flex items-center">
                                    <input type="checkbox" id={item.id} checked={Boolean(tempCheckedColumns[item.id])} onChange={handleCheckBox} className="mr-2" />
                                    <label htmlFor={item.id}>{item.label}</label>
                                </div>
                            ))}
                        </div>
                        <div className="font-bold text-xs text-[#647890] my-3 ">TABLE DENSITY</div>
                        <div className='w-full flex mt-5'>
                            <div onClick={() => setSelectedTab(1)} className={`border-2  text-[#647890] text-sm font-semibold cursor-pointer hover:bg-[#dbdbd9]  flex justify-center items-center w-1/2 py-1 ${selectedTab === 1 ? 'bg-[#fae396] border-[#ffe11f] text-black ' : ''}`}>
                                Wide
                            </div>
                            <div onClick={() => setSelectedTab(2)} className={` border-2  text-[#647890] text-sm font-semibold cursor-pointer  hover:bg-[#dbdbd9] flex  justify-center items-center w-1/2 py-1 ${selectedTab === 2 ? 'bg-[#fae396] border-[#ffe11f] ' : ''}`}>
                                Medium
                            </div>
                            <div onClick={() => setSelectedTab(3)} className={` border-2  text-[#647890] text-sm font-semibold cursor-pointer  hover:bg-[#dbdbd9] flex  justify-center items-center w-1/2 py-1 ${selectedTab === 3 ? 'bg-[#fae396] border-[#ffe11f] text-black' : ''}`}>
                                Narrow
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 flex p-1 justify-end items-center gap-6" >

                        <button className="font-semibold text-sm text-[#647890] active:translate-y-1 " onClick={() => { setTempCheckedColumns(settingReset) }}>Reset</button>
                        <button className="p-2 font-semibold text-sm bg-[#2196f3] text-white shadow-lg rounded-sm active:translate-y-1 " onClick={() => { setAnchorEl3(null); setTableColumns(tempCheckedColumns) }} >Apply</button>

                    </div>

                </div>

            </Popover>





        </div >
    )
}

export default TableExtendedToolTip
