import { baseURL } from '../Config';
import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, FormControl, Select, MenuItem } from '@mui/material';

const AccountDetails = () => {
    const [accountData, setAccountData] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            if (!token) return;
            const user = JSON.parse(localStorage.getItem('userInfo'))
            console.log(user.userId);
            try {
                const response = await fetch(`${baseURL}/user/profile/${user.userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();
                setAccountData(data || {});
            } catch (error) {
                console.error('Error fetching account data:', error);
            }
        };

        fetchData();
    }, []);

    if (!accountData) {
        return <Typography>Loading...</Typography>;
    }
    console.log(accountData, "dasd")


    return (
        <div style={{ fontFamily: 'Roboto, sans-serif', padding: '1rem', backgroundColor: "#f9fafb" }}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'left', padding: '0 20px', marginBottom: '10px' }}>
                Account Details
            </Typography>
            <Paper variant="outlined" style={{ padding: '10px', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '14px', margin: '0px 0px 10px 10', color: '#546478' }}>
                    BASICS
                </Typography>
                <form style={{ margin: '0 20px 0 0' }}>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                        <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                            CRN
                        </Typography>
                        <FormControl fullWidth>
                            <div className="base-Input-root">
                                <input
                                    className="base-Input-input"
                                    style={{ width: '35%', height: "30px", fontSize: '14px', marginLeft: '100px', border: "1px solid #B8B8B8", borderRadius: "4px", paddingLeft: "8px" }}
                                    value={accountData.crnNumber || 'Not Available'}
                                    readOnly
                                />
                            </div>
                        </FormControl>
                    </div>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                        <Typography variant="body2" style={{ marginRight: '8px', minWidth: '120px', fontSize: '14px', color: '#546478' }}>
                            Company Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <FormControl fullWidth>
                            <div className="base-Input-root">
                                <input
                                    className="base-Input-input"
                                    style={{ width: '36%', height: "30px", fontSize: '14px', marginLeft: '80px', border: "1px solid #B8B8B8", borderRadius: "4px", paddingLeft: "8px" }}
                                    value={accountData.name || 'Not Available'}
                                    readOnly
                                />
                            </div>
                        </FormControl>
                    </div>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                        <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                            Base currency
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                id="receipts3"
                                fullWidth
                                variant="outlined"
                                style={{ width: '35%', height: "30px", fontSize: '14px', marginLeft: '100px' }}
                                value={accountData.baseCurrency || 'Not Available'}
                                readOnly
                            >
                                <MenuItem value="Not Available">Not Available</MenuItem>
                                {/* Other options if required */}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                        <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                            Extract Tax
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                id="receipts3"
                                fullWidth
                                variant="outlined"
                                style={{ width: '35%', height: "30px", fontSize: '14px', marginLeft: '100px' }}
                                value={accountData.extractTax || 'Not Available'}
                                readOnly
                            >
                                <MenuItem value="Not Available">Not Available</MenuItem>
                                {/* Other options if required */}
                            </Select>
                        </FormControl>
                    </div>


                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                        <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                            Account Country
                        </Typography>
                        <FormControl fullWidth>
                            <div className="base-Input-root">
                                <input
                                    className="base-Input-input"
                                    style={{ width: '35%', height: "30px", fontSize: '14px', marginLeft: '100px', border: "1px solid #B8B8B8", borderRadius: "4px", paddingLeft: "8px" }}
                                    value={"UK"}
                                    readOnly
                                />
                            </div>
                        </FormControl>
                    </div>

                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', marginTop: "3px" }}>
                        <Typography variant="body2" style={{ marginRight: '8px', minWidth: '100px', fontSize: '14px', color: '#546478' }}>
                            Industry
                        </Typography>
                        <FormControl fullWidth>
                            <div className="base-Input-root">
                                <input
                                    className="base-Input-input"
                                    style={{ width: '35%', height: "30px", fontSize: '14px', marginLeft: '100px', border: "1px solid #B8B8B8", borderRadius: "4px", paddingLeft: "8px" }}
                                    value={"Acounting and Bookeeping"}
                                    readOnly
                                />
                            </div>
                        </FormControl>
                    </div>
                </form>
            </Paper>
            <Paper variant="outlined" style={{ padding: '1rem', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold' }}>
                <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', fontSize: '14px', margin: '0px 0px 10px 0' }}>
                    EMAIL-IN
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', width: '55%', marginLeft: '0' }}>
                    <Typography variant="body2" style={{ fontSize: '14px', color: '#546478' }}>
                        <ul style={{ paddingLeft: '0.5rem', wordSpacing: "3px", lineHeight: "18px", listStyleType: "disc" }}>
                            <li>Add documents to your Dext account by emailing them to the email addresses below.</li>
                            <li>Give the Costs email to your suppliers so they'll send their invoices straight to Prepare.</li>
                        </ul>
                    </Typography>
                </div>
            </Paper>
        </div>
    );
};

export default AccountDetails;

