import React, { useEffect, useState } from "react";
import { Button, Toolbar, Tooltip, IconButton, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { Tabs, Tab, Box, Select, MenuItem, TextField } from "@mui/material";

import TableComponent from "../components/Table";
import { Dropdown } from "flowbite-react";
import axios from 'axios';
import ExportDialog from "./ExportDiaolog";
import ExpenseDialog from "../expenseReportsComponents/ExpenseDailog";
import { baseURL } from '../Config';

import TableExtendedToolTip from "./utils/TableExtendedToolTip";

const token = localStorage.getItem('token');

function EnhancedTableToolbar({ rowsData, costsAvailable, numSelected, onArchiveClick, onExportClick, onExpanseClick, onDeleteClick, onCovertClick, onAddToExpenseClick }) {

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="flex items-center">
          <Button
            disabled={costsAvailable === 0 ? true : false}
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '8px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '#2196f3',
              color: '#fff',
              '&:hover': {
                bgcolor: '#2196f3',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              },
            }}
            onClick={onExportClick}
          >
            {numSelected === 0 ? 'Export All' : 'Export'}
          </Button>
          <Button
            disabled={numSelected === 0}
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '8px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '#2196f3',
              color: '#fff',
              '&:hover': {
                bgcolor: '#2196f3',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              },
            }}
            onClick={onArchiveClick}
          >
            Archive
          </Button>
          <Button
            disabled={numSelected === 0}
            variant="contained"
            sx={{
              fontSize: '12px',
              padding: '8px 15px',
              marginRight: '10px',
              fontWeight: 'bold',
              backgroundColor: '#2196f3',
              color: '#fff',
              '&:hover': {
                bgcolor: '#2196f3',
                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              },
            }}
            onClick={onAddToExpenseClick}
          >
            Add To Expense Report
          </Button>
          <Dropdown
            disabled={numSelected === 0}
            style={{
              borderRadius: '3px',
              background: '#2196f3',
              color: '#fff',
              fontWeight: 'bold',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
              padding: '0'
            }}
            label="Tools"
            dismissOnClick={false}
          >
            <Dropdown.Item style={{ filter: 'blur(2px)' }}>Merge</Dropdown.Item>
            <Dropdown.Item style={{ filter: 'blur(2px)' }}>Bulk edit</Dropdown.Item>
            <Dropdown.Item style={{ filter: 'blur(2px)' }}>Send by email</Dropdown.Item>
            <Dropdown.Item onClick={onCovertClick}>Convert to Sales</Dropdown.Item>
            <Dropdown.Item style={{ filter: 'blur(2px)' }}>Flag</Dropdown.Item>
            <Dropdown.Item style={{ filter: 'blur(2px)' }}>Unflag</Dropdown.Item>
            <Dropdown.Item onClick={onDeleteClick}>Delete</Dropdown.Item>
          </Dropdown>
        </div>
        <TableExtendedToolTip rowsData={rowsData} />
      </Toolbar>


    </>
  );
}

const ArchiveDialog = ({ open, onClose, selectedCount, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ color: "#2196f3" }}>Add Item(s)</DialogTitle>
      <DialogContent>
        <Typography>
          You have selected {selectedCount} item(s) to archive.
        </Typography>
        <DialogContentText>
          Are you sure you want to archive the selected items?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ background: "none", border: "none", '&:hover': { textDecoration: "underline" } }}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          color="primary"
          sx={{
            background: "#2196f3",
            border: "none",
            '&:hover': {
              border: "1px solid #2196f3",
              background: "#2196f3"
            },
            fontWeight: 'bold'
          }}
        >
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteDialog = ({ open, onClose, selectedCount, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ padding: '40px' }}>
      <DialogTitle sx={{ color: "#2196f3", fontWeight: "bold" }}>Delete Item(s)</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete the selected {selectedCount} item(s)?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ background: "none", border: "none", fontWeight: "bold", color: "#000099", '&:hover': { bgcolor: "#f0f0f0" } }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} sx={{ background: "#ff5a02", border: "none", color: "#ffff", fontWeight: "bold", '&:hover': { bgcolor: "#ff5a02" } }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConvertToSales = ({ open, onClose, selectedCount, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ padding: '40px' }}>
      <DialogTitle sx={{ color: "#2196f3", fontWeight: "bold" }}>Convert to Sale Item(s)</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to convert to sales {selectedCount} item(s)?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ background: "none", border: "none", fontWeight: "bold", color: "#000099", '&:hover': { bgcolor: "#f0f0f0" } }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} sx={{ background: "#ff5a02", border: "none", color: "#ffff", fontWeight: "bold", '&:hover': { bgcolor: "#ff5a02" } }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddToExpense = ({ open, onClose, selectedCount, onConfirm }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [dropdownValue, setDropdownValue] = useState("");
  const [formData, setFormData] = useState({
    claim: "",
    endDate: "",
    name: ""
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const tabContentStyle = {
    width: '400px', // Fixed width to ensure both tabs have the same width
    padding: '16px'
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ padding: '40px' }}>
      <DialogTitle sx={{ color: "#2196f3", fontWeight: "bold" }}>
        Add to Expense Item(s)
      </DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Existing Claim" />
          <Tab label="New Claim" />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {activeTab === 0 && (
            <Box sx={tabContentStyle}>
              <Select
                value={dropdownValue}
                onChange={handleDropdownChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>Select an option</MenuItem>
                <MenuItem value="option1">Option 1</MenuItem>
                <MenuItem value="option2">Option 2</MenuItem>
              </Select>
            </Box>
          )}
          {activeTab === 1 && (
            <Box sx={tabContentStyle}>
              <TextField
                required
                label="Claim"
                name="claim"
                value={formData.claim}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                required
                label="End Date"
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                required
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            background: "#dbdbd9",
            border: '2px solid #bfbfbf',
            fontWeight: "bold",
            color: "#bfbfbf",
            '&:hover': { bgcolor: "#f0f0f0" },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(formData)}
          sx={{
            background: "#dbdbd9",
            border: "2px solid #2196f3",
            color: '#647890',
            fontWeight: "bold",
            '&:hover': { backgroundColor: '#2196f3', color: 'white' },
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CostInbox = ({ fileURL }) => {

  const [selected, setSelected] = useState([]);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [expenseDialogOpen, setExpenseDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isConvertToSalesOpen, setIsConvertToSalesOpen] = useState(false);
  const [addToExpenseOpen, setAddToExpenseOpen] = useState(false);
  const [existingClaims] = useState([]);
  const [user, setUser] = useState({ name: "" });
  const [costs, setCosts] = useState([]);


  useEffect(() => {
    fetchCosts();
  }, [fileURL, !costs]);

  const fetchCosts = async () => {
    try {
      const response = await axios.get(`${baseURL}/cost`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const dataArray = Array.isArray(response.data) ? response.data : response.data.data;

      setCosts(dataArray);
      console.log(response, costs);
      // setCosts(response.data);
    } catch (error) {
      console.error('Error fetching costs:', error);
    }
  };

  const handleArchiveClick = () => {
    setIsArchiveDialogOpen(true);
  };

  const handleArchiveConfirm = async () => {
    console.log("archive", handleArchiveConfirm)
    try {
      await axios.patch(`${baseURL}/cost/status/${selected}`, { status: "archive", id: selected }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      console.log('Selected costs archived successfully');
      fetchCosts();
      setCosts(prevCosts => prevCosts.filter(cost => !selected.includes(cost._id)));
      setSelected([]);
      setIsArchiveDialogOpen(false);
    } catch (error) {
      console.error('Error archiving items:', error);
    }
  };



  const handleExpenseClick = () => {
    setExpenseDialogOpen(true);
  };

  const handleExpenseConfirm = (expenseData) => {
    setExpenseDialogOpen(false);
  };

  const handleArchiveCancel = () => {
    setIsArchiveDialogOpen(false);
  };

  const handleExportClick = () => {
    setIsExportDialogOpen(true);
  };

  const handleExportCancel = () => {
    setIsExportDialogOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleConvertClick = () => {
    setIsConvertToSalesOpen(true);
  };

  const handleAddToExpenseClick = () => {
    setAddToExpenseOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (Array.isArray(selected) && selected.length > 0) {
      console.log("Selected items:", selected);
      deleteCosts(selected);
    } else {
      console.error("Error: selected items should be an array of IDs.");
    }
    setIsDeleteDialogOpen(false);
  };

  const handleAddToExpenseCofo = async () => {
    if (Array.isArray(selected) && selected.length > 0) {
      console.log("Selected items:", selected);
      await axios.patch(`${baseURL}/cost/status/${selected}`, { status: "Expense Claim", id: selected }, {
        headers: { Authorization: `Bearer ${token}` }
      })
      console.log('Selected costs expense claim successfully');
      fetchCosts();
      setCosts(prevCosts => prevCosts.filter(cost => !selected.includes(cost._id)));
      setSelected([]);
    } else {
      console.error("Error: selected items should be an array of IDs.");
    }
    setAddToExpenseOpen(false);
  };


  const handleConvertConfirm = () => {
    if (Array.isArray(selected) && selected.length > 0) {
      console.log("Selected items Yashu:", selected);
      convertToSales(selected);
    } else {
      console.error("Error: selected items should be an array of IDs.");
    }
    setIsConvertToSalesOpen(false);
  };

  const handleAddToExpenseConfirm = () => {
    if (Array.isArray(selected) && selected.length > 0) {
      console.log("Selected items Yashu 2:", selected);

    } else {
      console.error("Error: selected items should be an array of IDs.");
    }
    setIsConvertToSalesOpen(false);
  };

  const convertToSales = async (selectedItems) => {
    try {
      const deletePromises = selectedItems.map(costId => {
        console.log(`Converting cost with ID: ${costId}`);
        return axios.post(`${baseURL}/cost/convert/${costId}`, {}, { // Empty object for data
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      });
      await Promise.all(deletePromises);
      setCosts(costs.filter(cost => !selectedItems.includes(cost._id)));
    } catch (error) {
      console.error('Error converting costs:', error);
    }
  };

  const deleteCosts = async (selectedItems) => {
    try {
      const deletePromises = selectedItems.map(costId => {
        console.log(`Deleting cost with ID: ${costId}`);
        return axios.delete(`${baseURL}/cost/${costId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
      });
      await Promise.all(deletePromises);
      setCosts(costs.filter(cost => !selectedItems.includes(cost._id)));
    } catch (error) {
      console.error('Error deleting costs:', error);
    }
  };


  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleConvertCancel = () => {
    setIsConvertToSalesOpen(false);
  };

  const handleAddToExpenseCancel = () => {
    setAddToExpenseOpen(false);
  };

  return (
    <>
      {/* <EnhancedTableToolbar costsAvailable={costs.length} numSelected={selected.length} onArchiveClick={handleArchiveClick} onExportClick={handleExportClick}
        onExpanseClick={handleExpenseClick} onDeleteClick={handleDeleteClick} onCovertClick={handleConvertClick} onAddToExpenseClick={handleAddToExpenseClick} />
      <TableComponent data={costs} selected={selected} setSelected={setSelected} fileURL={fileURL} />
      <ArchiveDialog
        open={isArchiveDialogOpen}
        onClose={handleArchiveCancel}
        selectedCount={selected.length}
        onConfirm={handleArchiveConfirm}
      />
      <ExpenseDialog
        open={expenseDialogOpen}
        onClose={() => setExpenseDialogOpen(false)}
        onConfirm={handleExpenseConfirm}
        user={user}
        existingClaims={existingClaims}
      />
      <AddToExpense open={addToExpenseOpen} onClose={handleAddToExpenseCancel} selectedCount={selected.length} onConfirm={handleAddToExpenseCofo} />
      <ConvertToSales open={isConvertToSalesOpen} onClose={handleConvertCancel} selectedCount={selected.length} onConfirm={handleConvertConfirm} />
      <ExportDialog
        open={isExportDialogOpen}
        onClose={handleExportCancel}
      />
      <DeleteDialog open={isDeleteDialogOpen} onClose={handleDeleteCancel} selectedCount={selected.length} onConfirm={handleDeleteConfirm} /> */}

      <>
        <EnhancedTableToolbar rowsData={costs} costsAvailable={costs.length} numSelected={selected.length} onArchiveClick={handleArchiveClick} onExportClick={handleExportClick}
          onExpanseClick={handleExpenseClick} onDeleteClick={handleDeleteClick} onCovertClick={handleConvertClick} onAddToExpenseClick={handleAddToExpenseClick} />
        <TableComponent data={costs} selected={selected} setSelected={setSelected} fileURL={fileURL} />
        <ArchiveDialog
          open={isArchiveDialogOpen}
          onClose={handleArchiveCancel}
          selectedCount={selected.length}
          onConfirm={handleArchiveConfirm}
        />
        <ExpenseDialog
          open={expenseDialogOpen}
          onClose={() => setExpenseDialogOpen(false)}
          onConfirm={handleExpenseConfirm}
          user={user}
          existingClaims={existingClaims}
        />
        <AddToExpense open={addToExpenseOpen} onClose={handleAddToExpenseCancel} selectedCount={selected.length} onConfirm={handleAddToExpenseCofo} />
        <ConvertToSales open={isConvertToSalesOpen} onClose={handleConvertCancel} selectedCount={selected.length} onConfirm={handleConvertConfirm} />
        <ExportDialog
          open={isExportDialogOpen}
          onClose={handleExportCancel}
          data={selected.length > 0 ? selected : costs}
        />
        <DeleteDialog open={isDeleteDialogOpen} onClose={handleDeleteCancel} selectedCount={selected.length} onConfirm={handleDeleteConfirm} />
      </>
    </>
  );
}

export default CostInbox;