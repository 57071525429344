import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Customers from '../listComponents/Customers';
import AllCustomer from '../listComponents/customers/AllCustomer';


const CustomerPage = () => {
    return (
        <div>
            <Routes className='p-4 h-full'>
                <Route path=":id" element={<AllCustomer />} />
                <Route path="" element={<Customers />} />
            </Routes>

        </div>
    )
}

export default CustomerPage
