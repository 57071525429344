// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, MenuItem, Grid, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Switch, FormControlLabel, } from '@mui/material';
// import HelpIcon from '@mui/icons-material/Help';
// import { styled } from '@mui/system';
// import {baseURL} from '../Config';
// const token = localStorage.getItem('token');

// const options = [
//   { value: 'option1', label: 'Option 1' },
//   { value: 'option2', label: 'Option 2' },
//   { value: 'option3', label: 'Option 3' },
// ];

// const StyledButton = styled(Button)(({ theme }) => ({
//   backgroundColor: '#1976d2',
//   color: '#ffff',
//   fontWeight: 'bold',
//   boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
//   border: '1px solid rgba(0, 0, 0, 0.1)',
//   '&:hover': {
//     backgroundColor: '#1976d2',
//     boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
//   },
// }));

// const AddButton = styled(Button)(({ theme }) => ({
//   backgroundColor: '#1976d2',
//   color: '#ffff',
//   fontWeight: 'bold',
//   '&:hover': {
//     backgroundColor: '#1976d2',
//     boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
//   },
//   boxShadow: '2px 2px 4px #1976d2',
//   border: '1px solid #1976d2',
// }));

// const CancelButton = styled(Button)(({ theme }) => ({
//   color: 'black',
//   fontWeight: 'bold',
//   '&:hover': {
//     backgroundColor: 'grey',
//   },
//   boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
//   border: '1px solid rgba(0, 0, 0, 0.1)',
// }));

// const DeleteButton = styled(Button)(({ theme }) => ({
//   color: '#ffff',
//   backgroundColor: '#1976d2',
//   fontWeight: 'bold',
//   padding: '6px 16px',
//   '&:hover': {
//     backgroundColor: '#1976d2',
//   },
//   boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
//   border: '1px solid rgba(0, 0, 0, 0.1)',
// }));

// const DeleteRowButton = styled(Button)(({ theme }) => ({
//   backgroundColor: '#ff5a02',
//   color: '#ffff',
//   fontWeight: 'bold',
//   '&:hover': {
//     backgroundColor: '#ff5a02',
//   },
//   boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
//   border: '1px solid rgba(0, 0, 0, 0.1)',
// }));

// const Categories = () => {
//   const [rows, setRows] = useState([]);
//   const [openAdd, setOpenAdd] = useState(false);
//   const [openImport, setOpenImport] = useState(false);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [name, setName] = useState('');
//   const [code, setCode] = useState('');
//   const [deleteIndex, setDeleteIndex] = useState(null);
//   const [selectedOption, setSelectedOption] = useState('');
//   const [selectedPopupOption, setSelectedPopupOption] = useState('');
//   const [categoryListName, setCategoryListName] = useState('');
//   const [popupRows, setPopupRows] = useState([{ name: '', code: '' }]);
//   const [defaultList, setDefaultList] = useState([]);
//   const [categoryData, setCategoryData] = useState({});
//   const [deleteCategory, setDeleteCategory] = useState(null);
//   const [groupId, setGroupId] = useState(null);


//   console.log(categoryData, "categoryData", popupRows, categoryListName);
//   // useEffect(() => {
//   //   const savedCategoryData = localStorage.getItem('categoryData');
//   //   if (savedCategoryData) {
//   //     // setCategoryData(JSON.parse(savedCategoryData))
//   //   }
//   // }, [])

//   useEffect(() => {
//     if (groupId) {
//       console.log(`Fetching categories for groupId: ${groupId}`);
//       fetchCategories(groupId);
//     } else {
//       console.log('groupId is null, not fetching categories');
//     }
//   }, [groupId]);

//   useEffect(() => {
//     fetchCategoriesByList()
//   }, [])

//   const fetchCategories = (groupId) => {
//     console.log(`Fetching categories for groupId: ${groupId}`);

//     axios.get(`${baseURL}/category/get-by-group/${groupId}`, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => {
//         console.log('Full GET response:', response);
//         console.log('Categories fetched:', response.data);

//         // if (Array.isArray(response.data)) {
//         //   const updatedCategoryData = {
//         //     ...categoryData,
//         //     [selectedOption]: response.data,
//         //   };
//         //   // console.log('Updated category data after fetch:', updatedCategoryData);
//         //   // setCategoryData(updatedCategoryData);
//         // } else {
//         //   console.error('Unexpected response format:', response.data);
//         // }
//       })
//       .catch(error => {
//         console.error('Error fetching categories:', error.response ? error.response.data : error.message);
//       });
//   };

//   const handleOpenAdd = () => {
//     setOpenAdd(true);
//   };

//   const handleCloseAdd = () => {
//     setOpenAdd(false);
//   };

//   const handleOpenImport = () => {
//     setOpenImport(true);
//   };

//   const handleCloseImport = () => {
//     setOpenImport(false);
//   };

//   // const handleOpenDelete = (index) => {
//   //   setDeleteIndex(index);
//   //   setOpenDelete(true);
//   // };

//   const handleOpenDelete = (index) => {
//     setDeleteCategory(index);
//     setOpenDelete(true);
//   };

//   const handleCloseDelete = () => {
//     setOpenDelete(false);
//   };

//   const handleAdd = () => {

//     const newRow = { name, code, visible: true, category: "test", group: groupId };
//     console.log('Payload being sent:', newRow);

//     axios.post(`${baseURL}/category`, newRow, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => {
//         console.log('Full POST response:', response);

//         const responseData = response.data;
//         console.log('POST response data:', responseData);

//         const responseGroupId = responseData.group || groupId;
//         console.log('Extracted _id from response or fallback to existing groupId:', responseGroupId);

//         if (responseGroupId) {
//           setGroupId(responseGroupId);

//           const newCategoryList = categoryData[selectedOption] || [];
//           const updatedCategoryData = {
//             ...categoryData,
//             [selectedOption]: [...newCategoryList, responseData],
//           };
//           console.log('Updated category data:', updatedCategoryData);
//           setCategoryData(updatedCategoryData);
//           setName('');
//           setCode('');
//           setOpenAdd(false);

//           console.log('Fetching categories for group:', responseGroupId);
//           fetchCategories(responseGroupId);
//         } else {
//           console.error('No valid group ID available to fetch categories');
//         }
//       })
//       .catch(error => {
//         console.error('Error making POST request:', error.response ? error.response.data : error.message);
//       });
//   };

//   const handleDeleteGroup = async (categoryId) => {
//     try {
//       const response = await fetch(`${baseURL}/category/group/${categoryId}`, {
//         method: 'DELETE',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         },
//       });

//       if (!response.ok) {
//         const errorText = await response.text();
//         throw new Error(`Failed to delete category: ${response.status} ${errorText}`);
//       }

//       // console.log('Category deleted successfully', await response.json());

//       fetchCategories(groupId);
//     } catch (error) {
//       console.error('Error deleting category:', error);
//     }
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (file && file.type === 'text/csv') {
//       console.log('CSV file uploaded:', file.name);
//       setOpenImport(false);
//     }
//   };

//   const handleSelectChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   const handlePopupSelectChange = (event) => {
//     setSelectedPopupOption(event.target.value);
//   };

//   const handleAddPopupRow = () => {
//     setPopupRows([...popupRows, { name: '', code: '' }]);
//   };
//   const fetchCategoriesByList = () => {
//     axios.get(`${baseURL}/category/group/get-all`, {
//       headers: {
//         'Authorization': `Bearer ${token}`
//       }
//     })
//       .then(response => {
//         setCategoryData(response.data);
//         console.log(response, "response get")
//       })
//       .catch(error => {
//         console.error('Error fecthing category List:', error);
//       });
//   };
//   const handlePopupSave = () => {
//     if (categoryListName.trim() !== '') {
//       const newList = {
//         name: categoryListName,
//         code: categoryListName,
//         Categories: categoryListName
//       };
//       console.log('Creating new list:', newList)

//       axios.post(`${baseURL}/category/group`, { name: categoryListName, categories: popupRows }, {
//         headers: {
//           'Authorization': `Bearer ${token}`
//         }
//       })
//         .then(response => {
//           // console.log(response, '-----------response')
//           console.log('Created Category Data:', response.data);
//           fetchCategoriesByList()

//           const newDefaultList = [
//             ...defaultList,
//             { value: categoryListName, label: categoryListName }
//           ];
//           setDefaultList(newDefaultList);
//           setSelectedOption(categoryListName);
//           // setCategoryData(response.data);
//           setSelectedPopupOption('');
//           setCategoryListName('');
//           setPopupRows([{ name: '', code: '' }]);
//           // handleCloseImport();
//         })
//         .catch(error => {
//           console.error('Error making Post request:', error)
//         });
//     };
//   }




//   const handlePopupRowChange = (index, field, value) => {
//     const updatedRows = [...popupRows];
//     updatedRows[index][field] = value;
//     setPopupRows(updatedRows);
//   };

//   return (
//     <>
//       <TableContainer sx={{ maxWidth: '80%', marginBottom: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', height: '100vh', padding: '16px', }}>
//         <Table sx={{ minWidth: 650 }}>
//           <TableHead>
//             <TableRow>
//               <TableCell colSpan={4} sx={{ fontSize: '18px', fontWeight: 'bold', borderBottom: 'none', marginBottom: '-10px' }}>
//                 Categories
//                 <Tooltip title="Help">
//                   <IconButton>
//                     <HelpIcon />
//                   </IconButton>
//                 </Tooltip>
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <div style={{
//             border: '1px solid #e0e0e0',
//             boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
//             width: '80%',
//             padding: '15px',
//             marginTop: "-5px",
//             overflowX: 'auto'
//           }}>
//             <TableContainer>
//               <Table sx={{ width: '100%' }}>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>
//                       <Grid container spacing={1} style={{ marginBottom: 10 }}>
//                         <Grid item>
//                           <StyledButton size="small" onClick={handleOpenAdd}>
//                             Add
//                           </StyledButton>
//                         </Grid>
//                         <Grid item>
//                           <StyledButton size="small" onClick={handleOpenImport}>
//                             Import
//                           </StyledButton>
//                         </Grid>
//                       </Grid>
//                     </TableCell>
//                     <TableCell colSpan={3}>
//                       <Grid container spacing={2} alignItems="center">
//                         <Grid item xs={6}>
//                           <TextField
//                             select
//                             variant="outlined"
//                             size="small"
//                             value={selectedOption}
//                             onChange={handleSelectChange}
//                             label="Select Group"
//                             sx={{ width: '100%' }}
//                           >
//                             {defaultList.map((option) => (
//                               <MenuItem key={option._id} value={option.name}>
//                                 {option.name}
//                               </MenuItem>
//                             ))}
//                           </TextField>
//                         </Grid>
//                         <Grid item xs={6}>
//                           <TextField
//                             select
//                             label="Manage groups"
//                             variant="outlined"
//                             size="small"
//                             value={selectedPopupOption}
//                             onChange={handlePopupSelectChange}
//                             sx={{ width: '100%' }}
//                           >
//                             <MenuItem value="popup1">Add Group</MenuItem>
//                             <MenuItem value="popup2" onClick={handleOpenImport}>
//                               Import group
//                             </MenuItem>
//                             <MenuItem value="popup3">Duplicate group</MenuItem>
//                           </TextField>
//                         </Grid>
//                       </Grid>
//                     </TableCell>
//                   </TableRow>
//                   <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
//                     <TableCell>Name</TableCell>
//                     <TableCell>Code</TableCell>
//                     <TableCell>Visible</TableCell>
//                     <TableCell></TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody sx={{
//                   width: '100%',
//                   borderCollapse: 'collapse',
//                   '& td, & th': {
//                     borderBottom: '1px solid #e0e0e0',
//                     padding: '6px',
//                     textAlign: 'left',
//                     fontSize: '14px',
//                   }
//                 }}>
//                   {categoryData && categoryData.length > 0 ? categoryData.map((row) => (
//                     <TableRow key={row._id}>
//                       <TableCell>
//                         <TextField size="small" variant="outlined" defaultValue={row.name} sx={{ width: '100%' }} />
//                       </TableCell>
//                       <TableCell>
//                         <TextField size="small" variant="outlined" defaultValue={row.code} sx={{ width: '100%' }} />
//                       </TableCell>
//                       <TableCell>
//                         <FormControlLabel
//                           control={
//                             <Switch
//                               checked={row.visible}
//                               onChange={() => {
//                                 const newCategoryData = { ...categoryData };
//                                 newCategoryData[selectedOption] = newCategoryData[selectedOption].map((item) =>
//                                   item._id === row._id ? { ...item, visible: !item.visible } : item
//                                 );
//                                 setCategoryData(newCategoryData);
//                                 localStorage.setItem('categoryData', JSON.stringify(newCategoryData));
//                               }}
//                               size="medium"
//                               sx={{
//                                 width: 65,
//                                 height: 48,
//                                 '& .MuiSwitch-switchBase': {
//                                   '&.Mui-checked': {
//                                     color: 'white',
//                                     marginLeft: "5px",
//                                     transform: 'translateX(20px)',
//                                     '& + .MuiSwitch-track': {
//                                       backgroundColor: '#1976d2',
//                                       opacity: 1,
//                                       border: '1px solid #1976d2',
//                                     },
//                                     '&:hover': {
//                                       backgroundColor: 'none',
//                                     },
//                                   },
//                                   '&.Mui-disabled + .MuiSwitch-track': {
//                                     opacity: 0.5,
//                                   },
//                                 },
//                                 '& .MuiSwitch-thumb': {
//                                   width: 18,
//                                   height: 18,
//                                   marginTop: "5.5px"
//                                 },
//                                 '& .MuiSwitch-track': {
//                                   borderRadius: 20 / 2,
//                                   backgroundColor: 'lightgrey',
//                                   opacity: 1,
//                                   transition: 'background-color 0.3s',
//                                 },
//                               }}
//                             />
//                           }
//                           label={row.visible ? 'on' : 'off'}
//                           labelPlacement="end"
//                           sx={{
//                             '.MuiFormControlLabel-label': {
//                               color: row.visible ? '#1976d2' : '#ffff',
//                               marginLeft: 2,
//                             },
//                           }}
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <DeleteRowButton size="small" onClick={() => handleOpenDelete(row._id)}>
//                           Delete
//                         </DeleteRowButton>
//                       </TableCell>
//                     </TableRow>
//                   )) : (
//                     <TableRow>
//                       <TableCell colSpan={4}>No data available</TableCell>
//                     </TableRow>
//                   )}

//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </div>

//         </Table>
//       </TableContainer>

//       <Dialog open={openAdd} onClose={handleCloseAdd} maxWidth="lg">
//         <DialogTitle style={{ fontWeight: 'bold', color: '#1976d2' }}>Add Row</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Name"
//             sx={{
//               minWidth: '320px', '& .MuiOutlinedInput-root': {
//                 '& input': {
//                   padding: '8px 10px',
//                 },
//               },
//             }}
//             variant="outlined"
//             margin="normal"
//             value={name}
//             onChange={(e) => setName(e.target.value)}
//           /><br />
//           <TextField
//             label="Code"
//             sx={{
//               minWidth: '320px', '& .MuiOutlinedInput-root': {
//                 '& input': {
//                   padding: '8px 10px',
//                 },
//               },
//             }}
//             variant="outlined"
//             margin="normal"
//             value={code}
//             onChange={(e) => setCode(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <AddButton onClick={handleAdd}>Add</AddButton>
//           <CancelButton onClick={handleCloseAdd}>Cancel</CancelButton>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={selectedPopupOption === 'popup1'} onClose={() => setSelectedPopupOption('')} maxWidth="sm" fullWidth>
//         <DialogTitle style={{ fontWeight: 'bold', color: '#1976d2' }}>Add New Category</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2} alignItems="center">
//             <Grid item>
//               <Typography variant="h6" style={{ fontWeight: 'bold', color: '#647890', fontSize: "16px" }}>Category List Name</Typography>
//             </Grid>
//             <Grid item>
//               <TextField
//                 value={categoryListName}
//                 onChange={(e) => setCategoryListName(e.target.value)}
//                 variant="outlined"
//                 size="small"
//                 margin="normal"
//                 sx={{ width: '390px', height: '40px' }}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={2} alignItems="flex-start">
//             <Grid item xs={6}>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   backgroundColor: '#f5f5f5',
//                   padding: '4px 8px',
//                   borderRadius: '4px',
//                   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//                   marginBottom: '8px',
//                 }}
//               >
//                 Name
//               </Typography>
//             </Grid>
//             <Grid item xs={6}>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   backgroundColor: '#f5f5f5',
//                   padding: '4px 8px',
//                   borderRadius: '4px',
//                   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//                   marginBottom: '8px',
//                 }}
//               >
//                 Code
//               </Typography>
//             </Grid>
//           </Grid>
//           {popupRows.map((row, index) => (
//             <Grid container spacing={2} key={index} alignItems="flex-start">
//               <Grid item xs={6}>
//                 <TextField
//                   fullWidth
//                   variant="outlined"
//                   size="small"
//                   value={row.name}
//                   onChange={(e) => {
//                     const newRows = [...popupRows];
//                     newRows[index].name = e.target.value;
//                     setPopupRows(newRows);
//                   }}
//                   margin="normal"
//                   sx={{ width: '100%' }}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <TextField
//                   fullWidth
//                   variant="outlined"
//                   size="small"
//                   value={row.code}
//                   onChange={(e) => {
//                     const newRows = [...popupRows];
//                     newRows[index].code = e.target.value;
//                     setPopupRows(newRows);
//                   }}
//                   margin="normal"
//                   sx={{ width: '100%' }}
//                 />
//               </Grid>
//             </Grid>
//           ))}
//           <Button
//             onClick={handleAddPopupRow}
//             style={{
//               backgroundColor: '#1976d2',
//               color: '#ffff',
//               borderRadius: '5px',
//               marginTop: '10px',
//               fontWeight: "bold",
//             }}
//             sx={{
//               '&:hover': {
//                 boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
//                 backgroundColor: '#1976d2',
//               },
//             }}
//           >
//             Add another category
//           </Button>
//         </DialogContent>
//         <DialogActions>
//           <AddButton onClick={handlePopupSave}>Save</AddButton>
//           <CancelButton onClick={() => setSelectedPopupOption('')}>Cancel</CancelButton>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={openImport} onClose={handleCloseImport}>
//         <DialogTitle style={{ fontWeight: 'bold', color: '#1976d2' }}>Import CSV</DialogTitle>
//         <DialogContent>
//           <input type="file" accept=".csv" onChange={handleFileUpload} />
//         </DialogContent>
//         <DialogActions>
//           <CancelButton onClick={handleCloseImport}>Cancel</CancelButton>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={openDelete} onClose={handleCloseDelete}>
//         <DialogTitle>Confirm Delete</DialogTitle>
//         <DialogContent>Are you sure you want to delete this row?</DialogContent>
//         <DialogActions>
//           <DeleteButton onClick={handleDeleteGroup}>Delete</DeleteButton>
//           <CancelButton onClick={handleCloseDelete}>Cancel</CancelButton>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default Categories;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
    Button, TextField, MenuItem, Grid, Tooltip, IconButton, Dialog, DialogTitle, 
    DialogContent, DialogActions, Typography, Switch, FormControlLabel 
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/system';
import { baseURL } from '../Config';

const token = localStorage.getItem('token');

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1976d2',
  color: '#ffff',
  fontWeight: 'bold',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: '#1976d2',
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1976d2',
  color: '#ffff',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#1976d2',
    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
  },
  boxShadow: '2px 2px 4px #1976d2',
  border: '1px solid #1976d2',
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: 'black',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: 'grey',
  },
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  color: '#ffff',
  backgroundColor: '#1976d2',
  fontWeight: 'bold',
  padding: '6px 16px',
  '&:hover': {
    backgroundColor: '#1976d2',
  },
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

const DeleteRowButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff5a02',
  color: '#ffff',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#ff5a02',
  },
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

const Categories = () => {
  const [rows, setRows] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [deleteCategory, setDeleteCategory] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedPopupOption, setSelectedPopupOption] = useState('');
  const [categoryListName, setCategoryListName] = useState('');
  const [popupRows, setPopupRows] = useState([{ name: '', code: '' }]);
  const [defaultList, setDefaultList] = useState([]);
  const [categoryData, setCategoryData] = useState({});
  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    fetchCategoriesByList();
  }, []);

  useEffect(() => {
    if (groupId) {
      fetchCategories(groupId);
    }
  }, [groupId]);

  // Fetch all category groups
  const fetchCategoriesByList = () => {
    axios.get(`${baseURL}/category/group/get-all`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setDefaultList(response.data);
      })
      .catch(error => {
        console.error('Error fetching category groups:', error);
      });
  };

  // Fetch categories within a selected group
  const fetchCategories = (groupId) => {
    axios.get(`${baseURL}/category/get-by-group/${groupId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setCategoryData(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenImport = () => {
    setOpenImport(true);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
  };

  const handleOpenDelete = (index) => {
    setDeleteCategory(index);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // Create a new category within the selected group
  const handleAdd = () => {
    const newCategory = { name, code, visible: true, group: groupId };

    axios.post(`${baseURL}/category`, newCategory, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        fetchCategories(groupId);
        setName('');
        setCode('');
        setOpenAdd(false);
      })
      .catch(error => {
        console.error('Error creating category:', error);
      });
  };

  // Create a new category group and assign categories to it
  const handlePopupSave = () => {
    if (categoryListName.trim() !== '') {
      const newList = {
        name: categoryListName,
        categories: popupRows
      };

      axios.post(`${baseURL}/category/group`, newList, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          fetchCategoriesByList();
          setGroupId(response.data._id);
          setCategoryListName('');
          setPopupRows([{ name: '', code: '' }]);
          setSelectedPopupOption('');
          setOpenImport(false);
        })
        .catch(error => {
          console.error('Error creating category group:', error);
        });
    }
  };

  // Handle deletion of a category group
  const handleDeleteGroup = async () => {
    try {
      const response = await axios.delete(`${baseURL}/category/group/${deleteCategory}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      if (response.status === 200) {
        fetchCategoriesByList();
        setOpenDelete(false);
      }
    } catch (error) {
      console.error('Error deleting category group:', error);
    }
  };
  

  const handleSelectChange = (event) => {
    const selectedGroup = event.target.value;
    setSelectedOption(selectedGroup);

    const group = defaultList.find(group => group.name === selectedGroup);
    if (group) {
      setGroupId(group._id);
    }
  };

  const handlePopupSelectChange = (event) => {
    setSelectedPopupOption(event.target.value);
  };

  const handleAddPopupRow = () => {
    setPopupRows([...popupRows, { name: '', code: '' }]);
  };

  // Handle changes in popup rows
  const handlePopupRowChange = (index, field, value) => {
    const updatedRows = [...popupRows];
    updatedRows[index][field] = value;
    setPopupRows(updatedRows);
  };

  // Handle file upload for importing CSV
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/csv') {
      console.log('CSV file uploaded:', file.name);
      // Further processing for the CSV file can go here
      setOpenImport(false);
    }
  };

  return (
    <>
      <TableContainer sx={{ maxWidth: '80%', marginBottom: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', height: '100vh', padding: '16px' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} sx={{ fontSize: '18px', fontWeight: 'bold', borderBottom: 'none', marginBottom: '-10px' }}>
                Categories
                <Tooltip title="Help">
                  <IconButton>
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <div style={{ border: '1px solid #e0e0e0', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', width: '80%', padding: '15px', marginTop: '-5px', overflowX: 'auto' }}>
            <TableContainer>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Grid container spacing={1} style={{ marginBottom: 10 }}>
                        <Grid item>
                          <StyledButton size="small" onClick={handleOpenAdd}>
                            Add
                          </StyledButton>
                        </Grid>
                        <Grid item>
                          <StyledButton size="small" onClick={handleOpenImport}>
                            Import
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6}>
                          <TextField
                            select
                            variant="outlined"
                            size="small"
                            value={selectedOption}
                            onChange={handleSelectChange}
                            label="Select Group"
                            sx={{ width: '100%' }}
                          >
                            {defaultList.map((option) => (
                              <MenuItem key={option._id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            select
                            label="Manage groups"
                            variant="outlined"
                            size="small"
                            value={selectedPopupOption}
                            onChange={handlePopupSelectChange}
                            sx={{ width: '100%' }}
                          >
                            <MenuItem value="popup1">Add Group</MenuItem>
                            <MenuItem value="popup2" onClick={handleOpenImport}>
                              Import group
                            </MenuItem>
                            <MenuItem value="popup3">Duplicate group</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell>Name</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Visible</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  '& td, & th': {
                    borderBottom: '1px solid #e0e0e0',
                    padding: '6px',
                    textAlign: 'left',
                    fontSize: '14px',
                  }
                }}>
                  {categoryData && categoryData.length > 0 ? categoryData.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell>
                        <TextField size="small" variant="outlined" defaultValue={row.name} sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <TextField size="small" variant="outlined" defaultValue={row.code} sx={{ width: '100%' }} />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={row.visible}
                              onChange={() => {
                                const newCategoryData = { ...categoryData };
                                newCategoryData[selectedOption] = newCategoryData[selectedOption].map((item) =>
                                  item._id === row._id ? { ...item, visible: !item.visible } : item
                                );
                                setCategoryData(newCategoryData);
                                localStorage.setItem('categoryData', JSON.stringify(newCategoryData));
                              }}
                              size="medium"
                              sx={{
                                width: 65,
                                height: 48,
                                '& .MuiSwitch-switchBase': {
                                  '&.Mui-checked': {
                                    color: 'white',
                                    marginLeft: "5px",
                                    transform: 'translateX(20px)',
                                    '& + .MuiSwitch-track': {
                                      backgroundColor: '#1976d2',
                                      opacity: 1,
                                      border: '1px solid #1976d2',
                                    },
                                    '&:hover': {
                                      backgroundColor: 'none',
                                    },
                                  },
                                  '&.Mui-disabled + .MuiSwitch-track': {
                                    opacity: 0.5,
                                  },
                                },
                                '& .MuiSwitch-thumb': {
                                  width: 18,
                                  height: 18,
                                  marginTop: "5.5px"
                                },
                                '& .MuiSwitch-track': {
                                  borderRadius: 20 / 2,
                                  backgroundColor: 'lightgrey',
                                  opacity: 1,
                                  transition: 'background-color 0.3s',
                                },
                              }}
                            />
                          }
                          label={row.visible ? 'on' : 'off'}
                          labelPlacement="end"
                          sx={{
                            '.MuiFormControlLabel-label': {
                              color: row.visible ? '#1976d2' : '#ffff',
                              marginLeft: 2,
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <DeleteRowButton size="small" onClick={() => handleOpenDelete(row._id)}>
                          Delete
                        </DeleteRowButton>
                      </TableCell>
                    </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell colSpan={4}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Table>
      </TableContainer>

      <Dialog open={openAdd} onClose={handleCloseAdd} maxWidth="lg">
        <DialogTitle style={{ fontWeight: 'bold', color: '#1976d2' }}>Add Row</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            sx={{
              minWidth: '320px', '& .MuiOutlinedInput-root': {
                '& input': {
                  padding: '8px 10px',
                },
              },
            }}
            variant="outlined"
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          /><br />
          <TextField
            label="Code"
            sx={{
              minWidth: '320px', '& .MuiOutlinedInput-root': {
                '& input': {
                  padding: '8px 10px',
                },
              },
            }}
            variant="outlined"
            margin="normal"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <AddButton onClick={handleAdd}>Add</AddButton>
          <CancelButton onClick={handleCloseAdd}>Cancel</CancelButton>
        </DialogActions>
      </Dialog>

      <Dialog open={selectedPopupOption === 'popup1'} onClose={() => setSelectedPopupOption('')} maxWidth="sm" fullWidth>
        <DialogTitle style={{ fontWeight: 'bold', color: '#1976d2' }}>Add New Category</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6" style={{ fontWeight: 'bold', color: '#647890', fontSize: "16px" }}>Category List Name</Typography>
            </Grid>
            <Grid item>
              <TextField
                value={categoryListName}
                onChange={(e) => setCategoryListName(e.target.value)}
                variant="outlined"
                size="small"
                margin="normal"
                sx={{ width: '390px', height: '40px' }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: '#f5f5f5',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  marginBottom: '8px',
                }}
              >
                Name
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: '#f5f5f5',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  marginBottom: '8px',
                }}
              >
                Code
              </Typography>
            </Grid>
          </Grid>
          {popupRows.map((row, index) => (
            <Grid container spacing={2} key={index} alignItems="flex-start">
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={row.name}
                  onChange={(e) => handlePopupRowChange(index, 'name', e.target.value)}
                  margin="normal"
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={row.code}
                  onChange={(e) => handlePopupRowChange(index, 'code', e.target.value)}
                  margin="normal"
                  sx={{ width: '100%' }}
                />
              </Grid>
            </Grid>
          ))}
          <Button
            onClick={handleAddPopupRow}
            style={{
              backgroundColor: '#1976d2',
              color: '#ffff',
              borderRadius: '5px',
              marginTop: '10px',
              fontWeight: "bold",
            }}
            sx={{
              '&:hover': {
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
                backgroundColor: '#1976d2',
              },
            }}
          >
            Add another category
          </Button>
        </DialogContent>
        <DialogActions>
          <AddButton onClick={handlePopupSave}>Save</AddButton>
          <CancelButton onClick={() => setSelectedPopupOption('')}>Cancel</CancelButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openImport} onClose={handleCloseImport}>
        <DialogTitle style={{ fontWeight: 'bold', color: '#1976d2' }}>Import CSV</DialogTitle>
        <DialogContent>
          <input type="file" accept=".csv" onChange={handleFileUpload} />
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleCloseImport}>Cancel</CancelButton>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>Are you sure you want to delete this row?</DialogContent>
        <DialogActions>
          <DeleteButton onClick={handleDeleteGroup}>Delete</DeleteButton>
          <CancelButton onClick={handleCloseDelete}>Cancel</CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Categories;
