import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

const MyPdfViewer = ({ fileUrl }) => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(null);

    const onDocumentLoad = ({ numPages }) => {
        setPages(numPages);
    };

    return (
        <div>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoad}>
                <Page pageNumber={page} />
            </Document>
        </div>
    );
};

export default MyPdfViewer;
